const getRGB = (hexcolor) => {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  return {r, g, b};
}

function getContrastYIQ(hexcolor) {
  const {r, g, b} = getRGB(hexcolor);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'black' : 'white';
}

const getSvgMarker = (dataUrl, color) => {
  const {r, g, b} = getRGB(color);

  const iconColor = getContrastYIQ(color);

  let decimalR = r / 255;
  let decimalG = g / 255;
  let decimalB = b / 255;

  if (iconColor === 'white') {
    decimalR = 1 - decimalR;
    decimalG = 1 - decimalG;
    decimalB = 1 - decimalB;
  }

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="544" height="752">
    <defs id="defs6">
        <filter filterUnits="objectBoundingBox" id="invert" x="0" y="0" height="1" width="1">
          <feColorMatrix type="matrix" 
          values="${decimalR}   0   0   0   0 
                  0   ${decimalG}   0   0   0  
                  0   0   ${decimalB}   0   0 
                  0   0   0   1   0" />
          ${iconColor === 'white' ? (`
            <feComponentTransfer>
              <feFuncR type="table" tableValues="1 0"/>
              <feFuncG type="table" tableValues="1 0"/>
              <feFuncB type="table" tableValues="1 0"/>
            </feComponentTransfer>
            `
        ) : ''}
        </filter>
      </defs>

      <clipPath id="clip">
          <path d="M 72 150 H 472 V 400 H 72"/>
      </clipPath>

      <image 
        filter="url(#invert)"
        id="clippedImage" 
        href="${dataUrl}"  
      />
    
    </svg>
  `;


  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
}

export default getSvgMarker;