import React, {useCallback, useEffect, useState} from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import _ from "lodash";
import getSvgMarker from "./get-svg-marker";
import http from '../../../http'

const GenericMarker = ({ ID, data }) => {
  const [movedMarker, setMovedMarker] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const [dataUrl, setDataUrl] = useState(null);

  const updateMarker = useCallback((e) => {
    setShowInfo(true);
    const newMarker = _.cloneDeep(data);
    const coords = JSON.stringify(e.latLng.toJSON(), null, 2);
    const newCords = JSON.parse(coords);
    const lat = newCords.lat;
    const lng = newCords.lng;
    newMarker.lat = lat;
    newMarker.lng = lng;
    setMovedMarker(newMarker);
  }, [data]);

  const draggable = (movedMarker && movedMarker.draggable) || (!movedMarker && data.draggable) || false;
  const icon = movedMarker?.icon || data.icon || 'default';
  const iconColor = movedMarker?.color || data.color || '#0000FF';
  const lat = (movedMarker && movedMarker.lat) || data.lat;
  const lng = (movedMarker && movedMarker.lng) || data.lng;

  useEffect(() => {
    const run = async () => {
      if(icon) {
        const result = await http.getIcon(`/assets/map-icons/${icon}-white.png`);

        const reader = new FileReader() ;
        reader.onload = function(){ setDataUrl(this.result); }; // <--- `this.result` contains a base64 data URI
        reader.readAsDataURL(result);
      }
    }

    run().then();
  }, [icon, iconColor])

  if(!dataUrl) {
    return null;
  }

  const url = getSvgMarker(dataUrl, iconColor);

  return (
    <>
      <Marker
        draggable={draggable}
        onDragEnd={(e) => updateMarker(e)}
        key={ID}
        position={{
          lat,
          lng,
        }}
        onClick={() => setShowInfo(true)}
        icon={{ url, scaledSize: { width: 50, height: 69 } }}
      >
        {showInfo && (
          <InfoWindow
            position={{
              lat,
              lng,
            }}
            options={{ disableAutoPan: true }}
            onCloseClick={() => setShowInfo(false)}
          >
            {data.type === "truck" &&
            <div style={{ color: 'black' }}>
              <div><b>Vehicle:</b> {data.vehicleID}</div>
              {!data.ticketCrn && <div><b>Ticket:</b> {data.ticket}</div>}
              {data.ticketCrn && <div><b>Ticket:</b> <a href={data.ticketUrl} target="_blank">{data.ticket}</a></div>}
            </div>
            ||
            <div style={{ color: 'black' }}>
              {data.info && data.info.map(i => {
                return (
                  <div key={i.name}><b>{i.name}:</b> {i.desc}</div>
                )
              })}
            </div>
            }
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};

export default GenericMarker;
