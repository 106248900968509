/*global google*/
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import GetMarkers from './GetMarkers';

const Map = ({
  center = { lat: 41.850033, lng: -87.6500523 },
  height = '100vh',
  mapTypeId = 'terrain',
  width = '50vw',
  zoom = 4,
  onZoomChanged = null,
  children,
  markers = [],
}) => {
  const [libraries] = useState(['places']);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < markers.length; i++) bounds.extend({ lat: markers[i].lat, lng: markers[i].lng });
      map.fitBounds(bounds);
      if (_.find(markers, { updateLocation: true })) {
        const curZoom = map.getZoom();
        map.setZoom(curZoom - 1);
        map.setMapTypeId('satellite');
      }
    }
  }, [map, markers]);

  const containerStyle = {
    width,
    height,
  };

  const loadScriptDependencies = {
    language: 'eng',
    // region: 'us',
    libraries,
    googleMapsApiKey: 'AIzaSyACAnfBsswnGoJJL8nAFoEmboEnU156mRA',
  };

  const handleZoomChange = () => {
    if (onZoomChanged) {
      onZoomChanged(map);
    }
  };

  const defaultMapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    gestureHandling: 'greedy',
  };

  const onLoad = React.useCallback(function callback(map) {
    if (markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < markers.length; i++) bounds.extend({ lat: markers[i].lat, lng: markers[i].lng });
      map.fitBounds(bounds);
      map.setZoom(1);
    }
    setMap(map);
  }, []);

  return (
    <LoadScriptNext {...loadScriptDependencies}>
      <GoogleMap
        onLoad={onLoad}
        options={defaultMapOptions}
        zoom={zoom}
        center={markers.length > 0 ? undefined : center}
        mapTypeId={mapTypeId}
        mapContainerStyle={containerStyle}
        onZoomChanged={handleZoomChange}
      >
        <GetMarkers markers={markers} />
        {children}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
