import React from 'react';
import Marker from './markers/Marker';

const GetMarkers = ({ markers }) => {
  if (markers && markers.length > 0) {
    return markers.map(marker => {
      const markerID = marker.vehicleID || marker.id;
      if (marker.type !== 'waypoint' && marker.type !== 'destination') {
        return <Marker key={markerID} ID={markerID} data={marker} />;
      }
    });
  }

  return <></>;
};

export default GetMarkers;
