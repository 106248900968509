import React, { useEffect, useRef } from 'react';
import { Translate } from '../../../../order/components/localization/Translate';
import { useTranslateMessage } from '../../../../order/components/localization/useTranslateMessage';
import DataLine from '../../components/data-line';
import styled from 'styled-components';
import cn from 'classnames';
import VehicleOrderStatus from '../../components/vehicle-order-status';
import { useDashboardContext } from '../../context/useDashboardContext';

import API from '../../../api';
import getGradient from '../../utils/get-gradient';

const vehicleStatusOrder = {
  PRINTED: 'Printed',
  LOADING_STARTED: 'Loading',
  LOADING_COMPLETE: 'Loaded',
  TO_JOB: 'To Job',
  ARRIVE_JOB: 'On Job',
  UNLOADING: 'Unloading',
  END_UNLOAD: 'Unloaded',
  WASHING: 'Washing',
  LEAVE_JOB: 'Leave Job',
};

const TrackingView = ({ setTitle, view = {}, className, node }) => {
  const { entityRef, setCurrentVehicles, openTicketDrawer, refreshDashboard } = useDashboardContext();
  const previousVehicles = useRef(null);

  useEffect(() => {
    setTitle(<Translate stringId="trackingWindow" defaultMessage="Tracking Window" />, node?._attributes.id);
    return () => {
      setCurrentVehicles([]);
    };
  }, [node?._attributes.id, setCurrentVehicles, setTitle]);

  useEffect(() => {
    if (view?.locations && view?.locations?.length > 0) {
      const vehicleMap = {};

      for (const location of view.locations) {
        for (const order of location.orders) {
          for (const vehicle of order.vehicles) {
            vehicleMap[vehicle.id] = vehicle;
          }
        }
      }

      const vehicleList = Object.keys(vehicleMap).map(key => vehicleMap[key]);

      vehicleList.sort((a, b) => (a.id < b.id ? -1 : 1));
      let updateVehicles = false;
      if (!previousVehicles.current) {
        updateVehicles = true;
      } else {
        if (vehicleList.length !== previousVehicles.current.length) {
          updateVehicles = true;
        } else {
          for (let i = 0; i < vehicleList.length; i++) {
            if (vehicleList[i].id !== previousVehicles.current[i].id) {
              updateVehicles = true;
              break;
            }
          }
        }
      }

      if (updateVehicles) {
        setCurrentVehicles(vehicleList);
        previousVehicles.current = vehicleList;
      }
    }
  }, [setCurrentVehicles, view]);

  const locations = view?.locations;

  const translateMessage = useTranslateMessage();

  const locationHeaderColumns = React.useMemo(() => {
    return view?.columnConfiguration?.location.map(l => ({
      value: translateMessage({ stringId: `tracking_location_${l.id}`, defaultMessage: l.label }),
    }));
  }, [translateMessage, view?.columnConfiguration?.location]);

  const orderHeaderColumns = React.useMemo(() => {
    return view?.columnConfiguration?.order.map(l => ({
      value: translateMessage({ stringId: `tracking_order_${l.id}`, defaultMessage: l.label }),
      width: l.width,
    }));
  }, [translateMessage, view?.columnConfiguration?.order]);

  const vehicleStatusColumns = React.useMemo(() => {
    return Object.keys(vehicleStatusOrder).map(key => ({
      value: translateMessage({ stringId: key, defaultMessage: vehicleStatusOrder[key] }),
    }));
  }, [translateMessage]);

  return (
    <div className={cn(className)}>
      <div className={'tracking-view-header'}>
        <DataLine type={'location'} header columns={locationHeaderColumns} />
        <DataLine type={'order'} header columns={orderHeaderColumns} resizable={true} />
        <DataLine type={'vehicle-status'} header columns={vehicleStatusColumns} />
      </div>
      <div className={'tracking-view-items'} data-testid={'tracking-list'}>
        {locations?.map((location, idx) => {
          let orders = location.orders;
          // filter out cancelled AND canceled orders
          orders = orders.filter(o => !['CANCELLED', 'CANCELED'].includes(o.supplierStatus?.name || 'PENDING'));
          const locationKey = location.columns?.map(c => c.value)?.join('#');
          return (
            <div
              key={`${locationKey}__${idx}`}
              style={{ flexDirection: 'column' }}
              data-testid={'location-tracking-list'}
            >
              <DataLine
                type={'location'}
                dropType={location.integratedSystem === 'CONNEX' ? 'vehicle' : ''}
                onDrop={vehicle => {
                  API.changeVehicleLocation(entityRef, vehicle.crn, 'to', location.crn).then(() => refreshDashboard);
                }}
                item={location}
                backgroundColor={location.backgroundColor}
                columns={location.columns}
                onDoubleClick={() => {}}
              >
                {orders.map(order => {
                  return (
                    <DataLine
                      key={`${order.crn}#${order?.supplierStatus?.name || 'PENDING'}`}
                      dropType={order.canTicket ? 'vehicle' : ''}
                      onDrop={vehicle => {
                        openTicketDrawer(
                          order.crn,
                          vehicle.carrierParty?.carrierRef || vehicle.carrierRef,
                          vehicle.crn,
                          vehicle?.configuration?.driver?.driverRef
                        );
                      }}
                      item={order}
                      backgroundColor={order.backgroundColor || '#FFFF00'}
                      type={'order'}
                      columns={order.columns}
                      onDoubleClick={() => {}}
                      resizable={true}
                    >
                      <VehicleOrderStatus vehicles={order.vehicles || []} />
                    </DataLine>
                  );
                })}
              </DataLine>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(TrackingView)`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .tracking-view-items {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 2em;
  }

  .location-tracking-line {
    background: ${props => getGradient(props.theme.locationLineBackground)};
    color: ${props => props.theme.locationLineForeground};
  }

  .order-tracking-line {
    background: ${props => getGradient(props.theme.locationLineBackground)};
    color: ${props => props.theme.orderLineForeground};
  }

  .vehicle-status-tracking-line {
    background: ${props => getGradient(props.theme.vehicleLineBackground)};
    color: ${props => props.theme.vehicleLineForeground};
  }
`;
