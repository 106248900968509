import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import DataGrid from '../../../components/data-grid/DataGrid';
import QuickDates from '../../../components/quick-dates/QuickDates';
import Spinner from '../../../components/spinner/Spinner';
import columns from './columnDef';
import style from './style';
import { useOrderListState } from './useOrderListState';

const OrderList = ({ className }) => {
  const { RowRenderer, onCreateClick, orders, ordersBusy, quickDate, handleQuickDateChange, datePickerProps, onOkay } =
    useOrderListState();

  return (
    <div className={cn(className)}>
      <div className="header">
        <div className="title">Order Requests</div>
        <div className="additional-actions">
          <Button type="primary" size="small" onClick={onCreateClick} icon={<i className="icon fas fa-plus" />}>
            Create Order Request
          </Button>
        </div>
      </div>

      <QuickDates
        onChange={handleQuickDateChange}
        value={quickDate}
        datePickerProps={datePickerProps}
        onOkay={onOkay}
      />

      <Spinner spin={ordersBusy}>
        <DataGrid columns={columns} rows={orders} rowRenderer={RowRenderer} />
      </Spinner>
    </div>
  );
};

export default styled(OrderList)`
  ${style}
`;
